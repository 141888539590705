<template lang="html">
    <div class="container-fluid p-5">
        <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        Product List (Active)
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row search-form">
                                    <div class="col-md-3 mt-1">
                                        <select id="inputShop" class="form-control" v-model="filter.shopId" @change="validationRule()">
										    <option value="">All Shop</option>
										    <option v-for="shop in shopList" :value="shop.id">{{shop.name}}</option>
									    </select>
                                    </div>
                                    <div class="col-md-3 mt-1">
                                        <select id="inputShop" class="form-control" v-model="filter.isExpire" @change="validationRule()">
										    <option value="">All Product</option>
                                            <option value="0">Not Expired</option>
										    <option value="1">Expired</option>
									    </select>
                                    </div>
                                    <div class="col-md-3 mt-1">
                                        <input type="text" v-model="filter.name" class="form-control" placeholder="Product Name">
                                    </div>
                                    <div class="col-md-3 mt-1">
                                        <button class="btn btn-primary mr-1 fbutton" @click="search()"><i class="fas fa-search"></i> Search</button>
                                        <button class="btn btn-info fbutton"  @click="reset()"><i class="fas fa-sync"></i> Reset</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mt-2">
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th class="th-width-100">Image</th>
                                                <th class="th-width-100">Shop</th>
                                                <th v-for="(value, key) in columns"
                                                    v-bind:class="{ 'asc': filter.sort == key && filter.reverse == 'desc', 'desc': filter.sort == key && filter.reverse == 'asc' }">
                                                    <a href="#" @click="sortBy(key)" class="text-capitalize">
                                                        {{value}}
                                                    </a>
                                                </th>
                                                <th class="th-width-100"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(data,index) in dataList"  :class="[data.is_expire == 1 ? 'bg-warning' : '']">
                                                <td><img :src="env.mediaUrl+data.image" class="tbl_image"/></td>
                                                <td class="pt-4">{{data.shop}}</td>
                                                <td class="pt-4">{{data.name}}</td>
                                                <td class="pt-4">{{data.price}}</td>
                                                <td class="pt-4">{{data.discountPrice}}</td>
                                                <td class="pt-4">
                                                    <span v-if="data.isHotdeals == false">NO</span>
                                                    <span v-if="data.isHotdeals == true">YES</span>
                                                </td>
                                                <td class="pt-4">
                                                    <span v-if="data.isBanner == false">NO</span>
                                                    <span v-if="data.isBanner == true">YES</span>
                                                </td>
                                                <td class="pt-4">
                                                    <span v-if="data.status == 1">Draft</span>
                                                    <span v-if="data.status == 2">Pending</span>
                                                    <span v-if="data.status == 3">Active</span>
                                                    <span v-if="data.status == 4">Inactive</span>
                                                </td>
                                                <!-- <td class="pt-4">
                                                    <span v-if="data.merchantStatus == 1">Draft</span>
                                                    <span v-if="data.merchantStatus == 2">Pending</span>
                                                    <span v-if="data.merchantStatus == 3">Active</span>
                                                    <span v-if="data.merchantStatus == 4">Inactive</span>
                                                </td> -->
                                                <td class="pt-2">
                                                    <button v-b-modal.bv-modal-noti class="btn btn-option text-primary"
                                                        @click="notiData(data)" v-if="checkPermission('productNoti') == true"><i class="fas fa-bell"></i></button>
                                                    <button class="btn btn-option text-primary"
                                                        @click="editData(data)" v-if="checkPermission('productEdit') == true"><i class="fas fa-edit"></i></button>
                                                    <button v-b-modal.bv-modal-delete
                                                        class="btn btn-option text-danger" v-if="checkPermission('productDelete') == true"><i
                                                            class="fas fa-trash-alt" @click="deleteData(data)"></i></button>
                                                </td>
                                            </tr>
                                            <tr v-if="dataList.length <= 0 ">
                                                <td colspan="9" class="text-center">Empty List</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <nav v-if="dataList.length > 0 " class="table_info">
                                        Showing {{metaData.from}} to {{metaData.to}} of {{metaData.total}} entries
                                    </nav>
                                    <nav class="paging_simple_numbers">
                                        <ul class="pagination pagination-md">
                                            <li class="page-item"
                                                :class="{ 'disabled': metaData.prev_page_url === null }">
                                                <a href="#" class="page-link" @click="next(metaData.current_page-1)">
                                                    &laquo;
                                                </a>
                                            </li>
                                            <li class="page-item" v-for="page in metaData.last_page" :key="page"
                                                :class="{ 'active':metaData.current_page === page }"
                                                v-if="Math.abs(page - metaData.current_page) < 3 || page == metaData.total - 1 || page == 0">
                                                <a href="#" @click.prevent="next(page)" class="page-link">
                                                    {{ page }}
                                                </a>
                                            </li>
                                            <li class="page-item"
                                                :class="{ 'disabled': metaData.current_page === metaData.last_page }">
                                                <a href="#" class="page-link" @click="next(metaData.current_page+1)">
                                                    &raquo;
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="bv-modal-delete" ref="my-modal" title="Delete" hide-footer>
            <p class="my-4">Are you sure to delete ? </p>
            <p class="my-4 text-danger">{{alert}}</p>
            <b-button class="mt-3 btn-danger" block @click="deleteItem()">Delete</b-button>
        </b-modal>
        <b-modal id="bv-modal-noti" ref="my-modal" title="Notification" hide-footer>
            <div class="d-block text-center">
                <p class="text-danger">{{this.alert}}</p>
                <p class="text-left">
                    <textarea class="form-control"  v-model="description" placeholder="Type Your Message Here"></textarea>
                </p>
            </div>
            <b-button class="mt-3 btn-success" block @click="notiSend()">Send</b-button>
        </b-modal>
    </div>
</template>

<script lang="js">
    import {
        mapState,
        mapActions
    } from 'vuex'
    import env from '../../enviorments'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        name: 'productList',
        metaInfo: {
            title: "Product List",
            titleTemplate: "%s ← HoHoDiDi Dashboard",
        },
        components: {
            Loading
        },
        data() {
            return {
                env,
                isLoading: false,
                fullPage: true,
                alert: "",
                id: '',
                description: '',
                dataList: [],
                shopList: [],
                permissions: '',
                columns: {
                    name: "name",
                    price: "price",
                    discount_price: "Discount Price",
                    is_hotdeals: "Is Hotdeals",
                    is_banner: "Is Banner",
                    status: "status",
                    // merchant_status: "Merchant Status"
                },
                filter: {
                    page: 1,
                    reverse: "asc",
                    sort: "created_at",
                    shopId: "",
                    isExpire: "",
                    name: "",
                    status: "pending"
                },
                metaData: {
                    last_page: null,
                    current_page: 1,
                    from: null,
                    to: null,
                    total: null,
                    prev_page_url: null
                }
            }
        },
        watch: {
        '$route' (to, from) {
        }
    },
        computed: {},
        methods: {
            ...mapActions({
                productListAction: 'productListAction',
                productDeleteAction: 'productDeleteAction',
                productNotiAction: 'productNotiAction',
                saleOrderFieldsAction: 'saleOrderFieldsAction'
            }),
            async getFields(){
				let option = {
					fields: {type: "list"}
				}
				await this.saleOrderFieldsAction({
					...option
				}).then(res => {
					this.shopList = res.data.data
					this.isLoading = false
				}).catch(err => this.isLoading = true)
			},
            sortBy(column) {
                this.filter.sort = column
                if (this.filter.sort == column) {
                    if (this.filter.reverse == "asc") {
                        this.filter.reverse = "desc"
                    } else if (this.filter.reverse == "desc") {
                        this.filter.reverse = "asc"
                    }
                } else {
                    this.filter.reverse = "asc"
                }
                this.fetchData()
            },
            async search() {
                this.filter.page = 1
                this.fetchData()
            },
            async fetchData() {
                this.isLoading = true
                let option = this.filter
                await this.productListAction({
                    ...option
                }).then(res => {
                    if (res.status == "success") {
                        this.dataList = res.data.data
                        this.metaData.last_page = res.data.meta.last_page
                        this.metaData.current_page = res.data.meta.current_page
                        this.metaData.prev_page_url = res.data.links.prev
                        this.metaData.from = res.data.meta.from
                        this.metaData.to = res.data.meta.to
                        this.metaData.total = res.data.meta.total
                        this.$router.replace({ path: 'product-active-list', query: { page: this.filter.page, reverse: this.filter.reverse, sort: this.filter.sort, shopId: this.filter.shopId, name: this.filter.name, isExpire: this.filter.isExpire, status: this.filter.status} }).catch(()=>{})
                        this.isLoading = false;
                    }
                }).catch(err => this.isLoading = true)
            },
            next: function (page) {
                this.filter.page = page
                this.fetchData();
            },
            reset(){
                this.$router.replace({ path: 'product-active-list', query: {page: 1, reverse: 'desc', sort: 'created_at', shopId: '', name: '', isExpire: '', status: 'active'} }).catch(()=>{})
                this.$router.go()
            },
            editData(data) {
                this.$router.replace({ path: 'product-edit', query: { page: this.filter.page, reverse: this.filter.reverse, sort: this.filter.sort, shopId: this.filter.shopId, id: data.id, name: this.filter.name } }).catch(()=>{})
            },
            deleteData(data){
                this.id = data.id
                this.alert = ""
            },
            async deleteItem() {
				this.isLoading = true
				let option = {
					id: this.id,
				}
				await this.productDeleteAction({
					...option
				}).then(res => {
                    if(res.status == 'fail'){
                        this.alert = res.message
                    }else{
                        this.$refs['my-modal'].hide()
                    }
                    this.fetchData()
                    this.isLoading = false;
				}).catch(err => this.isLoading = true)
			},
            notiData(data){
                this.alert = ""
                this.id = data.id
                this.description = data.description
            },
            async notiSend() {
				this.isLoading = true
				let option = {
					id: this.id,
                    description: this.description,
				}
				await this.productNotiAction({
					...option
				}).then(res => {
                    if(res.status == 'fail'){
                        this.alert = res.message
                    }else{
                        this.$refs['my-modal'].hide()
                    }
                    this.fetchData()
                    this.isLoading = false;
				}).catch(err => this.isLoading = true)
			},
            checkPermission: function (key) {
                if(key in this.permissions){
                    return true
                }else{
                    return false
                }
            }

        },
        mounted() {
            let user = JSON.parse(window.localStorage.getItem('user'))
            this.permissions = user.permissions
            this.filter.page = this.$route.query.page
            this.filter.reverse = this.$route.query.reverse
            this.filter.sort = this.$route.query.sort
            this.filter.shopId = this.$route.query.shopId
            this.filter.isExpire = this.$route.query.isExpire
            this.filter.name = this.$route.query.name
            this.filter.status = this.$route.query.status
            this.fetchData()
            this.getFields()
        }
    }
</script>

<style scoped lang="scss">
    .action {
        border: none;
        background: none;
        font-size: 24px;
    }
</style>